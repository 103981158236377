.modal-content {
    background: linear-gradient(145deg, #ffffff, #f1f1f1);
    padding: 2rem;
    border-radius: 20px;
    text-align: center;
    max-width: 450px;
    width: 90%;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.3s ease-in-out;
    overflow: hidden;
    position: relative;
    /* Ensure proper stacking */
}

.modal-content {
    background: linear-gradient(145deg, #ffffff, #f1f1f1);
    /* Gradient background */
    padding: 2rem;
    border-radius: 20px;
    /* Softer corners */
    text-align: center;
    max-width: 450px;
    width: 90%;
    position: absolute;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    /* Modern shadow for depth */
    animation: fadeIn 0.3s ease-in-out;
    /* Smooth appearance animation */
    overflow: hidden;
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    color: #333;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    transition: transform 0.2s;
}

.close-modal:hover {
    transform: scale(1.2);
    /* Slight zoom effect */
    color: #ff4d4d;
    /* Highlight on hover */
}

h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: #333;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    /* Modern font */
}

.iban-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
    padding: 0.8rem 1rem;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.currency {
    font-size: 1rem;
    color: #777;
    margin: 0;
    font-weight: bold;
}

.iban-text {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    flex: 1;
    text-align: center;
}

.copy-button {
    background: #4caf50;
    border: none;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    padding: 0.4rem 0.8rem;
    font-size: 0.9rem;
    font-family: 'Poppins', sans-serif;
    transition: background-color 0.3s ease, transform 0.2s;
}

.copy-button:hover {
    background-color: #45a049;
    transform: translateY(-2px);
    /* Lift effect */
}

.copy-button img {
    height: 20px;
    width: 20px;
}

button:focus {
    outline: none;
    /* Remove default focus outline */
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

@media (max-width: 768px) {


    .iban-container{
        width: 100%;
        align-self: center;
        padding: 0;
        flex-direction: column;
    }

    .iban-text{
        font-size: 1rem;
    }
}