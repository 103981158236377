.community-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    min-height: 100vh;
    padding: 2rem;
}

.title-community {
    font-size: clamp(2.5rem, 4vw, 4rem);
    /* Responsive font size */
    font-weight: bold;
    color: #333;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    /* Modern font */
}

.cards-container-community {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    width: 80vw;
    justify-content: space-between;
    align-items: stretch;
    /* Ensures all cards align with the tallest one */
}

.card-community {
    display: flex;
    align-items: center;
    flex: 0 1 calc(48% - 1rem);
    /* Two-column layout with space for gap */
    height: auto;
    /* Adjusts based on content */
    min-height: 25vh;
    /* Ensures a minimum height */
    border-radius: 20px;
    overflow: hidden;
    background: white;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    /* Softer shadow for depth */
    cursor: pointer;
    position: relative;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    /* Smooth interactions */
}

.card-community:hover {
    transform: scale(1.05);
    /* Slight zoom on hover */
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
    /* Enhanced shadow on hover */
}

.image-community {
    width: 40%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    transition: filter 0.3s ease;
    /* Smooth image hover effect */
}

.card-community:hover .image-community {
    filter: brightness(0.8);
    /* Slightly darkens image on hover */
}

.title-card-community {
    flex: 1;
    font-size: clamp(1.5rem, 2.5vw, 2rem);
    /* Responsive text size */
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    padding: 1rem;
    line-height: 1.5;
}

/* Responsive Adjustments */
@media (max-width: 950px) {
    .cards-container-community {
        flex-direction: column;
        /* Stacks the cards vertically */
        gap: 1.5rem;
    }

    .card-community {
        flex-direction: row;
        /* Keeps image on the left and text on the right */
        width: 100%;
        /* Full width */
        flex-basis: 0;
        height: 60vh;
        /* Adjust height dynamically */
    }

    .image-community {
        width: 50%;
        /* Image takes 50% of the card width */
        height: 100%;
        /* Stays full height */
        border-radius: 20px 0 0 20px;
        /* Adjusted border radius for row layout */
    }

    .title-card-community {
        width: 50%;
        /* Text takes up the other 50% */
        font-size: 1.8rem;
        padding: 1rem;
    }
}

@media (max-width: 480px) {
    .title-community {
        font-size: 2.5rem;
    }

    .card-community {
        flex-direction: column;
        /* Stack image and text vertically */
        width: 100%;
        /* Full width for mobile */
        align-items: center;
        /* Center align for better aesthetics */
        height: auto;
        /* Adjust height dynamically */
    }

    .image-community {
        width: 100%;
        /* Full width of the card */
        height: 50%;
        z-index: 0;
        /* Maintain aspect ratio */
        border-radius: 20px 20px 0 0;
        /* Rounded corners for top */
        object-fit: cover;
        /* Ensure the image scales proportionally */
        object-position: center 30%;
        /* Position image to show people better */
    }

    .title-card-community {
        width: 100%;
        z-index: 100;
        /* Full width for text */
        font-size: 1.4rem;
        /* Adjusted font size for smaller screens */
        padding: 1rem;
        text-align: center;
        /* Center align text for consistency */
    }
}