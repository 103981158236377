.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 6rem;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    /* Adds a glassy effect */
    color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 10000;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    /* Subtle shadow */
}

.logo-navbar {
    width: 7%;
    transition: transform 0.3s ease;
}

.logo-navbar:hover {
    transform: scale(1.1);
    /* Slight zoom on hover */
}

.navbar-doneaza .doneaza-button {
    background: linear-gradient(135deg, #03a59a, #028a83);
    /* Solid gradient */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 50px;
    font-weight: 600;
    width: 15vw;
    height: 5vh;
    box-shadow: 0 4px 15px rgba(3, 165, 154, 0.4);
    /* Glow effect */
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: clamp(1rem, 2vw + 0.7rem, 2rem);
    position: relative;
    transition: transform 0.2s ease-in-out, box-shadow 0.3s ease;
}

.navbar-doneaza .doneaza-button:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 20px rgba(3, 165, 154, 0.6);
}

.navbar-hamburger {
    font-size: 30px;
    cursor: pointer;
    color: #03a59a;
    /* Match brand color */
    margin-right: 5vw;
    text-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, color 0.3s ease;
}

.navbar-hamburger:hover {
    transform: scale(1.4);
    color: #028a83;
    /* Slightly darker tone */
}

.sidebar {
    position: fixed;
    top: 0;
    right: -60%;
    width: 250px;
    z-index: 10000;
    height: 100vh;
    background: linear-gradient(135deg, #03a59a, #028a83);
    color: white;
    font-size: 2rem;
    transition: right 0.4s ease, box-shadow 0.3s ease;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.2);
    /* Shadow for depth */
    padding: 20px;
    z-index: 1000000;
}

.sidebar.open {
    right: 0;
    width: 50vw;
}

.close-button {
    font-size: 3rem;
    cursor: pointer;
    text-align: right;
    color: #fff;
    transition: color 0.3s ease, transform 0.3s ease;
}

.close-button:hover {
    color: #c00;
}

.sidebar-menu {
    list-style: none;
    padding: 0;
    margin: 50px 0 0 0;
}

.sidebar-menu li {
    padding: 15px 0;
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    transition: color 0.3s ease, background-color 0.3s ease;
}

.sidebar-menu li:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.navbar-links {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.navbar-link-button {
    background: linear-gradient(135deg, #f04, #c00);
    /* Gradient red */
    color: white;
    padding: 10px 20px;
    margin: 3% 0;
    width: 80%;
    /* Adjust for consistency */
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(240, 64, 64, 0.4);
    /* Subtle glow */
    font-size: 1rem;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.navbar-link-button:hover {
    background: linear-gradient(135deg, #c00, #a00);
    transform: translateY(-3px);
    box-shadow: 0 6px 15px rgba(192, 0, 0, 0.6);
}

/* Media queries pentru responsive design */
@media (max-width: 1024px) {
    .logo-navbar {
        width: 15%;
    }

    .navbar-doneaza .doneaza-button {
        width: 20vw;
    }

}

@media (max-width: 768px) {
    .navbar {
        height: 6rem;
    }

    .logo-navbar {
        width: 20%;
    }

    .navbar-doneaza .doneaza-button {
        width: 25vw;
    }

    .navbar-hamburger {
        margin-right: 3%;
    }

    .sidebar-menu li {
        font-size: 1.5rem;
    }

        .navbar-link-button {
          
            margin: 3% 0;
            width: 60%;
        }

   
}

@media (max-width: 480px) {
    .navbar {
        height: 5rem;
    }

    .logo-navbar {
        width: 25%;
    }

    .sidebar-menu li {
        font-size: 1rem;
    }

    .navbar-link-button{
        font-size: 0.7rem;
    }

    .navbar-doneaza .doneaza-button {
        width: 30vw;
        font-size: 1rem;
    }

    .navbar-hamburger {
        margin-right: 0%;
        width: 15%;
    }


        .sidebar {
            right: -140%;
        }

}