.about-container {
    min-height: 100vh;
    display: flex;
    max-width: 100vw;
    flex-wrap: wrap;
    overflow: hidden;
    justify-content: space-evenly;
    align-items: center;
    padding: 2rem;
    box-sizing: border-box;
    /* Subtle background gradient */
}

.about-content {
    display: flex;
    justify-content: space-between;
    width: 80vw;
    flex-wrap: wrap;
    align-items: center;
}

.right-side-about {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.left-side-about {
    width: 35%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.about-image {
    width: 80%;
    max-width: 400px;
    border-radius: 15px;
    /* Rounded corners for the image */
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    /* Modern shadow effect */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.about-image:hover {
    transform: scale(1.05);
    /* Subtle zoom on hover */
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.title-about {
    font-size: clamp(2rem, 5vw, 4rem);
    /* Dynamic font size for responsiveness */
    margin: 0;
    color: #333;
    /* Neutral text color */
    font-weight: bold;
    text-align: left;
}

.subtitle-about {
    font-size: clamp(1.2rem, 4vw, 2.2rem);
    width: 80%;
    margin: 0;
    text-align: justify;
    color: #555;
    line-height: 1.5;
}

.text-about {
    font-size: clamp(1rem, 3vw, 1.5rem);
    width: 80%;
    text-align: justify;
    color: #777;
    line-height: 1.6;
    margin-top: 1rem;
}

.button-about-see-more {
    margin-top: 1.5rem;
    background: linear-gradient(135deg, #03a59a, #028a83);
    /* Gradient button */
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    border-radius: 50px;
    /* Rounded button */
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 5px 15px rgba(3, 165, 154, 0.4);
}

.button-about-see-more:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 20px rgba(3, 165, 154, 0.6);
}

@media (max-width: 1250px) {
    .about-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 2rem;
        padding: 1rem;
    }

    .left-side-about {
        display: none;
    }

    .right-side-about {
        width: 95vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .subtitle-about,
    .title-about {
        text-align: center;
    }

    .text-about{
        font-size: 1.3rem;
    }

    .about-image {
        width: 40%;
        max-width: 200px;
    }
}

@media (max-width: 768px) {

    .subtitle-about,
    .text-about {
        width: 90%;
    margin: 0.4rem;
    }

    .text-about{
        line-height: 1.4;
    }

    .button-about-see-more {
        width: 70%;
        text-align: center;
    }
}

@media (max-width: 480px) {

    .subtitle-about,
    .text-about {
        font-size: clamp(1rem, 4vw, 1.5rem);
    }

    .button-about-see-more {
        font-size: 0.9rem;
        padding: 8px 15px;
    }
}