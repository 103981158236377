.help-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.help-title {
    font-size: clamp(2.5rem, 4vw, 4rem);
    /* Responsive font size */
    font-weight: bold;
    color: var(--primary);
    text-align: center;
    font-family: 'Poppins', sans-serif;
    /* Modern font */
    margin-bottom: 2rem;
}

.help-cards-container {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    width: 70vw;
    justify-content: center;
    align-items: center;
}

.card-help {
    width: 100%;
    min-height: 25vh;
    height: auto;
    gap: 1rem;
    border-radius: 20px;
    background-color: var(--primary);
    overflow: hidden;
    padding: 1.5rem;
    display: flex;
    position: relative;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for depth */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    /* Smooth hover effect */
    cursor: pointer;
}

.card-help:hover {
    transform: translateY(-5px);
    /* Slight hover lift */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    /* Enhanced shadow on hover */
}

.image-holder-help {
    position: relative;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    /* Ensures images stay within rounded container */
    width: 30%;
    height: 100%;
}

.image-help-card {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
    /* Maintain aspect ratio */
    transition: transform 0.3s ease, filter 0.3s ease;
}

.card-help:hover .image-help-card {
    transform: scale(1.05);
    /* Slight zoom on hover */
    filter: brightness(0.9);
    /* Brighten image slightly */
}

.text-on-image {
    position: absolute;
    font-size: 1.5rem;
    color: white;
    text-align: center;
    width: 85%;
    text-shadow: 2px 2px 5px black;
    /* Enhanced text readability on images */
    overflow: hidden;
}

.data-help {
    display: flex;
    flex-direction: column;
    width: 65%;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    text-align: left;
}

.text-p-help {
    font-size: 1.4rem;
    color: white;
    margin: 0.5rem 0;
    font-family: 'Roboto', sans-serif;
    line-height: 1.6;
}

.anchor-help {
    text-decoration: none;
    color: white;
    font-weight: bold;
    text-decoration: underline;
    transition: color 0.3s ease;
}

.card2{
    font-size: 1.3rem;
}

.anchor-help:hover {
    color: rgba(255, 255, 255, 0.8);
    /* Subtle color change on hover */
}

/* Responsive Adjustments */
@media screen and (max-width: 1250px) {
    .help-cards-container {
        width: 90vw;
    }

    .card-help {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        padding: 1rem;
    }

    .image-holder-help {
        width: 100%;
        height: 400px;
    }

    .data-help {
        width: 100%;
        text-align: center;
        align-items: center;
    }

    .text-p-help {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 950px) {
    .help-cards-container {
        gap: 1.5rem;
    }

    .card-help {
        flex-direction: column;
        align-items: center;
        width: 80%;
    }

    .image-holder-help {
        width: 100%;
        height: 200px;
        /* Fixed height for consistent image appearance */
    }

    .data-help {
        text-align: center;
        align-items: center;
    }

    .text-p-help {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 480px) {
    .help-title {
        font-size: 2.5rem;
    }

    .card-help {
        flex-direction: column;
        padding: 1rem;
    }

    .card2{
        font-size: 1rem;
    }

    .image-holder-help {
        width: 100%;
        height: 150px;
        /* Smaller height for mobile */
    }

    .text-p-help {
        font-size: 1rem;
    }
}