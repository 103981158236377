.footer-container {
    min-height: 70vh;
    background: linear-gradient(0deg, rgb(7, 99, 99), #03A59A);
    /* Subtle gradient for a modern look */
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 2rem;
    color: #fff;
    /* Ensures consistent text color */
    box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.2);
    /* Adds a shadow at the top */
}

/* TOP FOOTER */
.top-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 2rem;
    justify-content: space-between;
    width: 90vw;
}

.image-footer {
    width: 100%;
    transition: transform 0.3s ease;
}



.image-holder {
    max-width: 10vw;
}

.image-contact-line {
    width: 20%;
    max-width: 100px;
}

.image-contact-line-social {
    width: 50%;
    max-width: 100px;
}

.image-contact-line-social:hover, .phone-line:hover, .anchor-footer:hover{
    transform: scale(1.1);
}

.contact-line-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    max-width: 35vw;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.phone-line {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
}

.telephone {
    flex-direction: column;
}

.anchor-footer {
    text-decoration: none;
    color: #ffffff;
    font-size: 1.5rem;
    width: fit-content;
    text-align: center;
    transition: color 0.3s ease;
}

.anchor-footer-social{
    width: 5vw;
}



.anchor-footer:hover {
    color: #cce7e7;
    /* Subtle color change on hover */
}

/* MIDDLE FOOTER */
.middle-footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    width: 90vw;
}

.first-row,
.second-row,
.third-row {
    width: 30%;
    font-size: 1.5rem;
    color: #ffffff;
    text-align: center;
    font-family: 'Roboto', sans-serif;
}

.anchor-location-footer {
    text-decoration: none;
    color: #ffffff;
    transition: color 0.3s ease;
}

.anchor-location-footer:hover {
    color: #cce7e7;
}

/* BOTTOM FOOTER */
.bottom-footer {
    width: 90vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    padding-top: 1rem;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    /* Adds separation line */
}

.third-line,
.upvisionmedia {
    width: 30%;
    font-size: 1.2rem;
    text-decoration: none;
    text-align: center;
}

.created {
    transition: transform 0.3s ease, color 0.3s ease;
    font-weight: bold;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    /* Centers content within the container */
    width: 30%;
    /* Ensures the container spans full width */
    text-align: center;
    /* Centers text content */
    padding: 1rem;
    margin: 0;
    /* Adds space around the text */
    box-sizing: border-box;
    /* Includes padding in the width calculation */
}

.upvisionmedia-logo {
    width: 50%;
    margin: 0;
    /* Matches parent container width */
    text-align: center;
    /* Ensures text is centered */
    display: flex;
    justify-content: center;
    align-items: center;
}

.upvisionmedia{
    font-size: 2rem;
}


.upvm {
    width: 100%;
    /* Matches parent container width */
    text-align: center;
}

.created:hover {
    transform: scale(1.1);
    /* Subtle hover effect */
    color: #cce7e7;
    /* Change text color on hover */
    cursor: pointer;
}

/* RESPONSIVENESS */

/* Tablet */
@media (max-width: 950px) {
    .footer-container {
        gap: 1rem;
        padding: 1rem;
    }

    .top-footer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90vw;
        gap: 1rem;
    }

    .image-holder {
        max-width: 20vw;
    }

    .image-contact-line {
        width: 15%;
        max-width: 100px;
    }
        .image-contact-line-social {
            width: 100%;
            max-width: 150px;
        }

    .contact-line-footer {
        max-width: 60vw;
    }

    .social-footer{
        gap: 5rem;
    }

    .anchor-footer {
        font-size: 1.3rem;
        width: 50vw;
    }

    .first-row,
    .second-row,
    .third-row {
        width: 100%;
        font-size: 1.3rem;
    }

    .third-line,
    .upvisionmedia {
        width: 90%;
    }

    .created{
        flex-direction: column;
        padding: 0;
    }

    .upvisionmedia{
        margin: 0;
        font-size: 1.5rem;
    }

    .upvm{
        width: 200%;
    }

    .bottom-footer {
        flex-direction: column;
        gap: 0.5rem;
        text-align: center;
    }
}

@media (max-width: 480px) {
    .footer-container {
        padding: 1rem;
    }

                .image-contact-line-social {
                    width: 200%;
                    max-width: 200px;
                }

    .image-footer {
        width: 70%;
        max-width: 200px;
    }

    .image-contact-line {
        width: 25%;
        max-width: 75px;
    }

    .anchor-footer {
        font-size: 1rem;
    }

    .phone-line, .social-footer, .contact-line-footer{
        width: 95vw;
    }

    .first-row,
    .second-row,
    .third-row {
        width: 100%;
        font-size: 1rem;
    }

    .third-line {
        max-width: 100%;
        font-size: 1.2rem;
    }
.created{width: 80%;}
}