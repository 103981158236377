.modal-footer {
    position: fixed;
    /* Ensures the modal is fixed and always visible */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    /* Dark overlay for better visibility */
    display: flex;
    /* Center the modal */
    justify-content: center;
    align-items: center;
    z-index: 10000;
    /* Ensure it appears above all other elements */
}

.modal-content-footer {
    background-color: white;
    color: black;
    margin: 0;
    /* No external margin to prevent misalignment */
    padding: 20px;
    border: none;
    height: 80%;
    width: 80%;
    overflow-y: auto;
    max-width: 600px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
    /* Slight shadow for depth */
    text-align: left;
    position: relative;
    /* Ensures proper positioning of children */
    animation: fadeIn 0.3s ease-in-out;
    /* Smooth fade-in animation */
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

.close {
    color: #555;
    font-size: 1.5rem;
    font-weight: bold;
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
    transition: color 0.3s ease;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
}

.open-modal-button {
    padding: 10px 20px;
    background-color: var(--primary);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.open-modal-button:hover {
    background-color: #45a049;
}