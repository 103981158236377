.how-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    position: relative;
}

.how-title {
    font-size: clamp(2.5rem, 4vw, 4rem);
    /* Responsive font size */
    font-weight: bold;
    color: #333;
    margin-bottom: 2rem;
    text-align: center;
    font-family: 'Poppins', sans-serif;
}

.container-cards-how {
    display: flex;
    width: 80vw;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
    row-gap: 2rem;
    position: relative;
}

.card-how {
    width: 35vw;
    min-width: 30vw;
    height: 25vh;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    /* Softer shadow for depth */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    /* Smooth interactions */
    cursor: pointer;
}

.card-how:hover {
    transform: translateY(-10px);
    /* Lift effect */
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
    /* Enhanced shadow on hover */
}

.image-card-how {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 20px;
    /* Matches card's border-radius */
    filter: brightness(0.7);
    /* Darken image for better text contrast */
    transition: filter 0.3s ease;
}

.card-how:hover .image-card-how {
    filter: brightness(0.9);
    /* Lighten slightly on hover */
}

.title-card-how {
    position: relative;
    font-size: clamp(1.8rem, 3vw, 3rem);
    /* Responsive font size */
    color: #fff;
    text-align: center;
    text-shadow: 0 4px 10px rgba(0, 0, 0, 0.7);
    /* Enhance readability */
    z-index: 1;
    /* Ensure the title stays above the image */
    pointer-events: none;
    /* Prevent interactions with text */
}

/* Responsive Adjustments */
@media screen and (max-width: 1200px) {
    .title-card-how {
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 950px) {
    .card-how {
        width: 80vw;
        min-width: 80vw;
        height: auto;
    }

    .image-card-how {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 15px;
    }

    .how-title {
        font-size: 3rem;
    }

    .title-card-how {
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 480px) {
    .how-title {
        font-size: 2.5rem;
        margin-bottom: 1.5rem;
    }

    .card-how {
        width: 90%;
        height: auto;
    }

    .title-card-how {
        font-size: 2rem;
    }
}