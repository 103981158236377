.home-container {
    min-height: 100vh;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    box-sizing: border-box;
}

.top-home {
    display: flex;
    flex-wrap: wrap;
    width: 80vw;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
}

.left-side-home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2.5rem;
    max-width: 45%;
}

.right-side-home {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 50%;
    overflow: hidden;
}

.header-image {
    width: 100%;
    max-height: 70vh;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}

.home-title {
    font-size: clamp(2rem, 5vw, 4.5rem);
    font-weight: bold;
    color: #333;
    text-align: left;
    line-height: 1.2;
    margin: 0;
}

.subtitle-home {
    font-size: clamp(1.2rem, 3vw, 1.8rem);
    font-weight: 400;
    color: #555;
    text-align: left;
    margin: 0;
}

.home-donate {
    background: linear-gradient(45deg, #03a59a, #029a8c);
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    border: none;
    border-radius: 10px;
    padding: 1rem 2rem;
    cursor: pointer;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.home-donate:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}

.home-donate:active {
    transform: scale(0.98);
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
}

/* RESPONSIVE STYLES */
@media (max-width: 1200px) {
    .top-home {
        flex-direction: row;
        gap: 1.rem;
        align-items: flex-start;
    }

    .left-side-home {
        max-width: 60%;
        gap: 1rem;
    }

    .right-side-home {
        max-width: 35%;
    }

    .header-image {
        max-height: 50vh;
    }
}

@media (max-width: 950px) {
    .home-container {
        padding: 1.5rem;
    }

    .top-home {
        flex-direction: column;
        align-items: center;
        gap: 2rem;
    }

    .left-side-home {
        align-items: center;
        text-align: center;
        max-width: 100%;
        gap: 1rem;
    }

    .right-side-home {
        display: block;
        width: 90%;
        max-width: 100%;
    }

    .header-image {
        display: block;
        width: 100%;
        max-height: 40vh;
        margin-bottom: 1rem;
    }

    .home-title {
        font-size: clamp(2.5rem, 4vw, 4rem);
        text-align: center;
        width: 75%;
    }

    .subtitle-home {
        font-size: clamp(1.2rem, 3vw, 1.5rem);
        text-align: center;
    }

    .home-donate {
        width: 80%;
        font-size: 1.3rem;
        padding: 1rem;
    }
}

@media (max-width: 480px) {
    .home-container {
        padding: 1rem;
    }

    .top-home {
        width: 100%;
    }

    .left-side-home {
        max-width: 100%;
    }

    .right-side-home {
        width: 100%;
    }

    .header-image {
        display: block;
        width: 100%;
        max-height: 30vh;
        margin-bottom: 1rem;
    }

    .home-title {
        font-size: clamp(1.5rem, 5vw, 2.5rem);
    }

    .subtitle-home {
        font-size: clamp(1rem, 4vw, 1.5rem);
    }

    .home-donate {
        width: 100%;
        font-size: 1rem;
        padding: 1rem;
    }
}