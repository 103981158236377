.contact-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title-contact {
    font-size: clamp(2.5rem, 4vw, 4rem);
    /* Responsive font size */
    font-weight: bold;
    color: var(--primary);
    text-align: center;
    margin-bottom: 2rem;
    font-family: 'Poppins', sans-serif;
    /* Modern font */
}

.low-contact {
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    width: 70vw;
    background-color: var(--primary);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for depth */
    justify-content: center;
    align-items: center;
    overflow: hidden;
align-items: stretch;
    /* Ensures child elements stretch to equal height */
    /* Ensures content stays within rounded corners */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    /* Smooth hover effects */
}


.form-contact {
    width: 50%;
    padding: 2rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    background: #ffffff;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    /* Soft shadow for form */
}

.form-container {
    width: 95%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* Space between inputs */
}

.input-holder {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    /* Space between input fields */
}

.input-first {
    display: flex;
    gap: 1rem;
    width: 100%;
}

.input-first .input {
    flex: 1;
}

.input {
    padding: 1rem;
    font-size: 1rem;
    color: #4a5568;
    border: 1px solid #ccc;
    border-radius: 10px;
    transition: all 0.3s ease;
    width: 100%;
}

.input:focus {
    border-color: var(--primary);
    outline: none;
    box-shadow: 0 0 8px rgba(0, 128, 128, 0.3);
    /* Subtle glow on focus */
}

.input2 {
    padding: 1rem;
    width: 100%;
    font-size: 1rem;
    color: #4a5568;
    border: 1px solid #ccc;
    border-radius: 10px;
    transition: all 0.3s ease;
}

.input2:focus {
    border-color: var(--primary);
    outline: none;
    box-shadow: 0 0 8px rgba(0, 128, 128, 0.3);
}

.submit {
    background-color: var(--primary);
    color: #fff;
    border: none;
    width: auto;
    height: auto;
    padding: 0.75rem 2rem;
    border-radius: 30px;
    font-size: 1.5rem;
    cursor: pointer;
    align-self: flex-end;
    transition: background-color 0.3s ease, transform 0.3s ease;
    font-family: 'Poppins', sans-serif;
}

.submit:hover {
    background-color: darken(var(--primary), 10%);
    transform: translateY(-2px);
    /* Slight lift on hover */
}

.map-location {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 20px 20px 0;
    overflow: hidden;
}

.map {
    border-radius: 0 20px 20px 0;
    object-fit: cover;
}

/* Loading Spinner */
.spinner {
    border: 3px solid #f3f3f3;
    border-top: 3px solid #03A59A;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Success Animation */
.success-checkmark {
    width: 40px;
    height: 40px;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #03A59A;
    border-radius: 50%;
}

.check {
    width: 14px;
    height: 28px;
    border: 3px solid #fff;
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
}

.error-cross {
    width: 40px;
    height: 40px;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ff4d4d;
    border-radius: 50%;
}

.error-cross:before,
.error-cross:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 3px;
    background: #fff;
    border-radius: 2px;
}

.error-cross:before {
    transform: rotate(45deg);
}

.error-cross:after {
    transform: rotate(-45deg);
}
@media (max-width: 950px) {

    .contact-container{
        padding: 1rem;
    }
    .low-contact {
        flex-direction: column;
        width: 80%;
        align-items: center;
        gap: 2rem;
        padding: 1rem;
    }

    .form-contact {
        width: 100%;
        align-items: center;
        padding: 0;
        padding:  1rem 0;
    }

    .input, .input2{
        width: 80%;
    }

    .map-location {
        width: 100%;
        height: auto;
        border-radius: 15px;
    }
}

@media (max-width: 480px) {
    .low-contact {
        gap: 1rem;
    }

    .form-contact {
        padding: 1rem;
    }

    .title-contact {
        font-size: 2.5rem;
    }

    .input,
    .input2 {
        font-size: 1rem;
    }

    .submit {
        font-size: 1.2rem;
        padding: 0.5rem 1.5rem;
    }

    .map {
        height: auto;
        max-height: 300px;
        object-fit: contain;
    }
}