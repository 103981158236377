/* src/styling/ModalAbout.css */
.modal-overlay-about {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    /* Darker overlay for better contrast */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    backdrop-filter: blur(5px);
    /* Adds a subtle blur effect */
}

.modal-content-about {
    background: linear-gradient(135deg, #ffffff, #f8f9fa);
    /* Gradient for a clean, modern look */
    padding: 2rem;
    max-width: 55vw;
    border-radius: 15px;
    /* Rounded corners for a smoother look */
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
    /* Enhanced shadow for depth */
    text-align: left;
    overflow-y: auto;
    max-height: 75vh;
    animation: fadeIn 0.4s ease;
    /* Smooth fade-in animation */
}

/* Title Styles */
.title-modal-about {
    margin-bottom: 20px;
    font-size: 2.5rem;
    text-align: center;
    color: #333;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    /* Modern font */
}

/* Section Styles */
.modal-content-about section {
    margin-bottom: 20px;
}

.section-modal-about {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.title-section-modal-about {
    font-size: 2rem;
    margin-bottom: 10px;
    color: var(--primary);
    font-weight: bold;
}

.subtitle-modal-about {
    font-size: 1.1rem;
    margin-top: 10px;
    color: #666;
    line-height: 1.5;
}
.section2 {
    display: flex;
    flex-direction: column-reverse;
    /* Adjust layout for better balance */
    align-items: center;
    gap: 1.5rem;
}

.section2 img {
    margin-top: 1rem;
}

.ul-about-modal{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

/* Paragraph Styles */
.para-modal-about {
    margin-bottom: 15px;
    line-height: 1.8;
    max-width: 80%;
    text-align: justify;
    color: #555;
    font-family: 'Roboto', sans-serif;
    /* Clean font */
}

/* List Styles */
.modal-content-about ul {
    list-style-type: disc;
    margin-left: 1.5rem;
    padding-left: 1rem;
}

.modal-content-about li {
    margin-bottom: 10px;
    color: #555;
    font-size: 1rem;
    line-height: 1.5;
}

/* Custom Scrollbar Styling */
.modal-content-about::-webkit-scrollbar {
    width: 10px;
}

.modal-content-about::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 10px;
}

.modal-content-about::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 10px;
    border: 3px solid transparent;
    background-clip: padding-box;
}

.modal-content-about::-webkit-scrollbar-thumb:hover {
    background-color: #bbb;
}

/* Firefox scrollbar styling */
.modal-content-about {
    scrollbar-width: thin;
    scrollbar-color: #cccccc #f0f0f0;
}

/* Image Styles */
.image-about-modal {
    width: 50%;
    border-radius: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-about-modal:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
}

/* Fade-in Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

/* Responsiveness */
@media (max-width: 768px) {
    .modal-content-about {
        max-width: 90%;
        padding: 1.5rem;
    }

    .title-modal-about {
        font-size: 2rem;
    }

    .subtitle-modal-about,
    .para-modal-about {
        font-size: 1rem;
    }

    .image-about-modal {
        width: 70%;
    }
}