/* src/styling/ModalCommunity.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    /* Darker overlay for better focus */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    backdrop-filter: blur(5px);
    /* Adds a blur effect for a modern touch */
}

.modal-content {
    background: linear-gradient(135deg, #ffffff, #f9f9f9);
    /* Subtle gradient for a clean look */
    padding: 2rem;
    border-radius: 20px;
    /* Rounded corners for a smoother design */
    max-width: 700px;
    width: 90%;
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
    /* Enhanced shadow for depth */
    text-align: left;
    overflow-y: auto;
    max-height: 80vh;
    animation: fadeIn 0.4s ease;
    /* Smooth fade-in animation */
}

/* Title Styles */
.title-modal-community {
    margin-bottom: 1.5rem;
    font-size: 2.5rem;
    text-align: center;
    color: var(--primary);
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    /* Modern font */
    border-bottom: 2px solid var(--primary);
    /* Adds a subtle underline */
    padding-bottom: 0.5rem;
}

/* Subtitle Styles */
.subtitle-modal-community {
    color: #444;
    font-size: 1.5rem;
    margin-top: 1rem;
    text-align: center;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    /* Clean font for readability */
}

/* Content Paragraph Styles */
.modal-content p {
    font-size: 1rem;
    color: #555;
    line-height: 1.8;
    margin-bottom: 1rem;
    font-family: 'Roboto', sans-serif;
}

/* Button Close */
.modal-content button.close-button {
    background: var(--primary);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 1rem;
    align-self: center;
    display: block;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.modal-content button.close-button:hover {
    background: darken(var(--primary), 10%);
    transform: scale(1.05);
    /* Slight zoom effect on hover */
}

/* Custom Scrollbar */
.modal-content::-webkit-scrollbar {
    width: 10px;
}

.modal-content::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.modal-content::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: padding-box;
}

.modal-content::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}

/* Fade-in Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

/* Responsiveness */
@media (max-width: 768px) {
    .modal-content {
        padding: 1.5rem;
        max-width: 90%;
    }

    .title-modal-community {
        font-size: 2rem;
    }

    .subtitle-modal-community {
        font-size: 1.2rem;
    }

    .modal-content p {
        font-size: 0.9rem;
    }
}